<template>
  <div class="border-b border-gray-200">
    <button
      class="flex w-full items-center justify-between py-4 text-left"
      :class="{ 'pb-0': isOpen }"
      @click="toggle"
    >
      <h3 class="font-bold text-[18px] text-brand-black pr-8">{{ title }}</h3>
      <span class="flex items-center">
        <svg
          class="h-6 w-6 transform transition-transform duration-200"
          :class="{ 'rotate-180': isOpen }"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </span>
    </button>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="transform scale-y-95 opacity-0"
      enter-to-class="transform scale-y-100 opacity-100"
      leave-active-class="transition duration-200 ease-out"
      leave-from-class="transform scale-y-100 opacity-100"
      leave-to-class="transform scale-y-95 opacity-0"
    >
      <div v-show="isOpen" class="pb-4 pt-4">
        <div class="text-gray-600 leading-relaxed space-y-4" v-html="content"></div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  content: {
    type: String,
    required: true
  }
});

const isOpen = ref(false);

const toggle = () => {
  isOpen.value = !isOpen.value;
};
</script>
