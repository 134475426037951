<!-- eslint-disable prettier/prettier -->
<template>
  <HeroGallery :images="images">
    <template #title>
      Make Cool Cash by <br class="hidden md:inline-block" />
      Referring Top Talent
    </template>
    <template #description>
      Join our referral program and earn rewards for connecting talented professionals with great opportunities.
      <br class="hidden md:inline-block" />
      Your network is valuable - turn it into a rewarding side hustle.
    </template>
  </HeroGallery>

  <Customers />

  <!-- Steps Section -->
  <StepsLight
    title="Join, Refer & Earn"
    subtitle="How it works"
    :steps="steps"
  />

  <!-- Benefits Section -->
  <div class="flex justify-center flex-col bg-light-yellow/50 relative overflow-hidden py-20">
    <div class="w-full px-4 md:px-0 relative z-10">
      <div class="max-w-[900px] mx-auto mb-12">
        <h2 class="text-center text-[28px] md:text-[40px] text-brand-black leading-[120%] font-semibold mb-4">
          Why Join <span class="text-[#B45309]">Talent Connect?</span>
        </h2>
        <p class="text-center text-[16px] text-[#475467] leading-[150%] max-w-[600px] mx-auto">Benefits for Recruiters & Experts</p>
      </div>
    </div>
    <div class="lg:w-[78vw] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <div
        v-for="benefit in benefits"
        :key="benefit.title"
        class="bg-white p-6 rounded-xl transition-all duration-300 hover:shadow-lg hover:-translate-y-1 border border-gray-100"
      >
        <div class="w-10 h-10 bg-brand-primary/10 rounded-lg flex items-center justify-center mb-4">
          <img :src="benefit.icon" class="w-5 h-5" :alt="benefit.title" />
        </div>
        <h3 class="font-semibold text-[#1D2939] mb-2">{{ benefit.title }}</h3>
        <p class="text-sm text-gray-600">{{ benefit.description }}</p>
      </div>
    </div>
  </div>

  <!-- Expectations Section -->
  <ExpectationsLight
    title="What We Expect"
    subtitle="Essential requirements"
    :expectations="expectations"
  />

  <div class="py-20 px-4">
    <LandingFAQ :faqs="faqs" />
  </div>
</template>

<script setup>
import { useMeta } from '@/utils/meta';
import LandingSection from '@/components/partials/LandingSection.vue';
import LandingCard from '@/components/partials/LandingCard.vue';
import HeroGallery from '@/components/partials/HeroGallery.vue';
import Customers from '@/components/partials/Customers.vue';
import LandingFeatureGrid from '@/components/partials/LandingFeatureGrid.vue';
import LandingFAQ from '@/components/partials/LandingFAQ.vue';
import { ref } from 'vue';

const images = {
  one: require('@/assets/images/h11.jpeg'),
  two: require('@/assets/images/h12.jpeg'),
  three: require('@/assets/images/h1.jpeg'),
  four: require('@/assets/images/h2.jpeg'),
  five: require('@/assets/images/h3.jpeg'),
};
import Accordion from '@/components/common/Accordion.vue';
import StepsLight from '@/components/StepsLight.vue';
import ExpectationsLight from '@/components/ExpectationsLight.vue';

// Step icons
import signupIcon from '@/assets/icons/referral/signup.svg';
import browseIcon from '@/assets/icons/referral/browse.svg';
import referIcon from '@/assets/icons/referral/refer.svg';
import earnIcon from '@/assets/icons/referral/earn.svg';

// Benefit icons
import remoteIcon from '@/assets/icons/remote.svg';
import earningsIcon from '@/assets/icons/earnings.svg';
import dashboardIcon from '@/assets/icons/dashboard.svg';
import networkIcon from '@/assets/icons/network.svg';

// Expectation icons
import timeIcon from '@/assets/icons/time.svg';
import targetIcon from '@/assets/icons/target.svg';
import communicationIcon from '@/assets/icons/communication.svg';

const candidateUrl = ref(process.env.VUE_APP_CANDIDATE_URL);

const steps = [
  {
    icon: signupIcon,
    title: 'Sign up',
    description: 'Create an account with us.',
  },
  {
    icon: browseIcon,
    title: 'Browse jobs',
    description: 'Explore exciting job openings and their requirements.',
  },
  {
    icon: referIcon,
    title: 'Refer',
    description: 'Recommend professionals who are a great fit.',
  },
  {
    icon: earnIcon,
    title: 'Earn',
    description: 'Receive a referral bonus when your candidate is hired.',
  },
];

const benefits = [
  {
    title: 'Remote Work',
    description: 'Work from anywhere, anytime. Enjoy complete flexibility in your schedule.',
    icon: remoteIcon,
  },
  {
    title: 'Competitive Earnings',
    description: 'Earn attractive bonuses based on successful placements. The more you refer, the more you earn.',
    icon: earningsIcon,
  },
  {
    title: 'Track Progress',
    description: 'Monitor your referred candidates and earnings on your personalized dashboard.',
    icon: dashboardIcon,
  },
  {
    title: 'Global Network',
    description: 'Collaborate with innovative startups across Africa and beyond. Expand your professional network.',
    icon: networkIcon,
  },
];

const expectations = [
  {
    title: 'Time Management',
    description: 'Strong time management and understanding of hiring timelines.',
    icon: timeIcon,
  },
  {
    title: 'Goal Oriented',
    description: 'Result-driven and focus on achieving goals.',
    icon: targetIcon,
  },
  {
    title: 'Professional Communication',
    description: 'Excellent communication and the ability to maintain confidentiality when required.',
    icon: communicationIcon,
  },
];

const faqs = [
  {
    question: 'What is CareerBuddy Talent Connect?',
    answer:
      'CareerBuddy Talent Connect is a community of recruiters and industry experts who use their networks and sourcing expertise to connect talent with high-potential opportunities. By joining, you can earn while enjoying the flexibility and independence of freelancing, alongside a supportive community of like-minded professionals.',
  },
  {
    question: 'How does CareerBuddy Talent Connect work?',
    answer: `
      <ul class="list-disc pl-5">
        <li>Browse roles and refer suitable candidates.</li>
        <li>Our internal recruiters screen and interview qualified candidates.</li>
        <li>You earn a referral bonus on every successful hire.</li>
      </ul>
    `,
  },
  {
    question: 'How do I start earning by referring professionals?',
    answer: `
      <p>To start earning, simply:</p>
      <ul class="list-disc pl-5">
        <li>Sign up on thecareerbuddy.com.</li>
        <li>Refer qualified candidates for open roles.</li>
        <li>Earn when your candidates are successfully placed</li>
      </ul>
    `,
  },
  {
    question: 'Who can join the Talent Connect Network?',
    answer: 'The network is open to recruiters and individuals with strong networks of high-quality professionals.',
  },
  {
    question: 'Is CareerBuddy Talent Connect limited to African companies?',
    answer: 'Not at all! CareerBuddy works with companies across Africa and beyond, so you can refer professionals globally.',
  },
  {
    question: 'What is the referral bonus for successful hires?',
    answer:
      'Referral bonuses can go up to NGN 200k per role. Each role on the job board indicates its specific referral bonus, so you always know what you stand to earn.',
  },
  {
    question: 'How do referrers get Paid?',
    answer: `
      <p>Payments are split into two parts:</p>
      <ul class="list-disc pl-5">
        <li>50% after we receive payment from our client.</li>
        <li>50% after the candidate successfully completes their probation period.</li>
      </ul>
    `,
  },
  {
    question: 'What is the probation period for roles?',
    answer: 'We offer clients a 90-day probationary period for all hires.',
  },
];

const heroDescription = `
  <p>Are you a recruiter or industry expert with a strong network of quality professionals? Put your skills and network to work, connect top talent to great opportunities, and earn up to <span class="text-light-yellow font-semibold">NGN 200k</span> for every successful placement.</p>
`;

useMeta();
</script>
